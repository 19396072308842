import React from 'react'
import Slider from "react-slick"

import CardBox from '../molecules/CardBox'

function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  centerMode,
  centerPadding
}) {

  const settings = {
    centerMode: centerMode ? centerMode : false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    centerPadding: centerPadding ? centerPadding : '20px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  }

  return (
    <div className={`mc-slider-rotator`}>
      <Slider {...settings}>
        {list.map((slide, index) => {

          return (
            <div key={index} className='mc-slider-rotator__slide'>
              <CardBox
                image={slide.image}
                title={slide.title}
                desc={slide.desc}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SliderRotator