
import React, { useState } from 'react'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'

const BonusReminder = () => {
  const [showHideToast, setShowHideToast] = useState(false);
  
  return (
    <div className={`BonusReminder ${showHideToast ? 'close' : ''}`}>
      <div className="content">
        <p>Recuerda que tienes un bono esperando por ti 🎁.</p>
        <div className="bt-Bonus-Reminder">
          <Button
            type='secondary'
            text='Canjear'
            link='/premios'
            status={'active'}   
               
          />
        </div>
        <div className="close-btn" onClick={() => setShowHideToast(!showHideToast)}>
          <Icon name='close-btn' />
        </div>
      </div>
    </div>
  )
}

export default BonusReminder
