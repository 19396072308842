import React, { useContext } from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import CardRetos from './CardRetos'
import { StateController } from '../../App'

const SectionRetos = () => {
  const { context } = useContext(StateController)
  console.log(context)
  return (
    <section className='SectionRetos'>
      <Container>
        <Columns>
          <Col>
            <article className='challenger-content'>
              <h2>Retos</h2>
              <h3>A lo largo de la campaña, te informaremos sobre los retos que tendrás que cumplir.</h3>
              <p className='desc-challenger'>Por cada reto que superes, adicional al bono de tu meta, participarás en el sorteo de un bono de Amazon de USD $200.
              Si ganas, te enviaremos el bono por correo.</p>
            </article>
          </Col>
          <Col>
            <article className='challenger-cards'>
                <CardRetos disabled={true} date='25 de noviembre al 1 de diciembre' logo='reto1.png' numberChallenger={1} challenger={`Realiza ${(context && context.user && context.user.challenge_1) ? context.user.challenge_1 : '<xx>'} compras por un monto mínimo que te comunicaremos por correo.`} successChallenger={(context && context.tracing && context.tracing.progress_challenge_1 === 1) ? 'success' : (context && context.tracing && context.tracing.progress_challenge_1 === 0) ? 'failed' : false } />
                <CardRetos disabled={true} date='12 de diciembre al 20 de diciembre' win logo='reto2.png' numberChallenger={2} challenger={`Difiere tus compras a mínimo 3 meses`} successChallenger={(context && context.tracing && context.tracing.progress_challenge_1 === 1) ? 'success' : (context && context.tracing && context.tracing.progress_challenge_1 === 0) ? 'failed' : false } />
            </article>
          </Col>
        </Columns>

      </Container>
    </section>
  )
}

export default SectionRetos
