import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FaqsFeed from '../organisms/FaqsFeed'
import Container from '../../layouts/Container/Container'
import Icon from '../atoms/Icon'
import { StateController } from '../../App'

const SectionFAQS = () => {
  const { staticContent } = useContext(StateController)
  const [faqrsStatic, setFaqrsStatic] = useState([]);
  console.log(faqrsStatic)
  useEffect(() =>{
    if(staticContent && staticContent.faqs){
      setFaqrsStatic(staticContent?.faqs?.slice(0,4))
    }
  },[staticContent])
  return (
    <section className='SectionFAQS'>

      <Container>
        <article>
          <h4>Preguntas frecuentes</h4>
        </article>
        <article>


          <FaqsFeed
            faqsType={1}
            list={ faqrsStatic }
          />
          <div className='link-to-faqrs'>
            <Link to={'/preguntas-frecuentes'}>Ver todo <Icon name='arrow-link' /> </Link>
          </div>
        </article>
      </Container>



    </section>
  )
}

export default SectionFAQS
