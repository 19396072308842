import React from 'react'
import Icon from '../atoms/Icon'

const CardRetos = ({ logo, numberChallenger, successChallenger, challenger, date, disabled}) => {
  return (
    <div className={`CardRetos ${disabled ? 'disabled' : ''}`}>
      <div className="card-content">
        <div className="card-header">
          <div className="logo">
            <img src={`/assets/images/${logo}`} alt="" />
          </div>
          <div className="header-text">
            <h3>Reto {numberChallenger} </h3>
            <p className='value-reto'>Bono de {`$200`}</p>
          </div>
        </div>
        <div className="card-body">
          <p className="desc-reto">
          {
            challenger
          }
          </p>
          <p>
            <strong>Tiempo:</strong> {date}
          </p>
          <h4 className={!successChallenger ? 'no-challenger' : ''}>
            {
              successChallenger === 'failed' ?
                <>
                  Reto no cumplido <Icon name='challenger-failed' />
                </>
                :
                successChallenger === 'success' ?

                <>
                Reto cumplido <Icon name='challenger-success' />
                </>
                :
                <></>
            }
          </h4>
        </div>
        {/* <div className={`card-footer ${ win ? 'winBG' : ''}`}>
          <h5>

            {
              win ?
                  <>¡Felicitaciones a nuestros ganadores! Pronto recibirán un correo anunciando los nombres de quienes superaron el reto. No te lo pierdas. </>
                :
                <>
                  Los ganadores de este reto serán anunciados por correo.
                  ¡No te lo pierdas!
                </>
            }

          </h5>
        </div> */}
      </div>


    </div>
  )
}

export default CardRetos
