import React, { useEffect } from 'react'
import Container from '../../layouts/Container/Container'
import Button from "../atoms/Button";
function NotFound({ loggedIn }) {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page not-found slow_ani'>
      <Container>
        <div className="customRow">
          <div className="customCol1">
            <div className="content-404">
              <h2>No encontramos lo que buscas</h2>
              <p>Ve a la página de <b>Progreso</b> para que revises cómo va tu meta
                y qué tan cerca estás de ganar tu bono mientras
                usas tu PacifiCard Mastercard.</p>
              <Button
                type='primary'
                text='Ir a Progreso'
                status='active'
                link={loggedIn ? '/progreso' : '/'}
                icon='arrowBtn404'
              />
            </div>
          </div>
          <div className="customCol2">
            <img src="/assets/images/err-404.png" alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}
export default NotFound