import React, { useEffect, useContext, useState } from 'react'
import { Link } from "react-router-dom"
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import FormQuantumData from './FormQuantumData'
import { sendDataLayer } from '../../utils/functions'

function CustomModal({
  token,
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  desc,
  cta,
  twoStepAuth,
  location,
  brand_id,
  quantumName,
  setQuantumName,
  quantumDoc,
  setQuantumDoc,
  department,
  city,
  site,
  setDepartment,
  setCity,
  setSite,
  redemtion,
  awardSelected,
  globalModal,
  iconClose,
  specialAward,
  segment,
  titleModal
}) {
  const { setMdalOpen } = useContext(StateController)
  const [twoStepAuthStatus, setTwoStepAuthStatus] = useState(false)
  const [expDate, setExpDate] = useState('')
  const [primaryActionStatus, setPrimaryActionStatus] = useState('inactive')
  const [dropTyc, setDropTyc] = useState(false);

  const [errors, setErrors] = useState({
    expDate: false
  })

  const handleOpenTyc = ()=>{
    setDropTyc(!dropTyc)
    sendDataLayer({ datalayer: { event: "bond_tyc", product_name: awardSelected?.name, product_price: awardSelected?.price }, repeat: true })
  }
  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  const close = () => {
    if (redemtion) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index }, repeat: true })
    }
    setShowModal(false)
  }

  const handleChange = async (e) => {
    const element = e.target.getAttribute('rel')

    if (element === 'birthdate') {
      setExpDate(e.target.value)
      setErrors({ ...errors, expDate: false })

      setTwoStepAuthStatus(true)
    }
  }

  useEffect(() => {

    if (
      (twoStepAuth && twoStepAuthStatus) ||
      (location && location === 1 && quantumName !== '' && quantumDoc !== '') ||
      (location && location === 2 && site?.selected)
    ) {
      setPrimaryActionStatus('active')
    } else {
      setPrimaryActionStatus('inactive')
    }

    if (!twoStepAuth && location === 0) setPrimaryActionStatus('active')

  }, [twoStepAuth, location, quantumName, quantumDoc, site])

  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani ${redemtion ? 'modalRedemptions' : ''} ${globalModal ? 'globalModal' : ''} ${specialAward ? 'specialAward' : ''}`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter top={(redemtion || specialAward) ? true : false}>
          <div className="mc-custom-modal__center--window slow_ani">

            {
              redemtion ?
                <>
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="mc-custom-modal__close" onClick={close}>
                        <Icon name="close" />
                      </div>

                      <h4>Este es tu bono</h4>


                    </div>
                    <div className="modal-body">
                      <div className="award-info">
                        {image &&
                          <div
                            className="mc-custom-modal__center--window__image"
                            style={{ "backgroundImage": `url(/assets/images/gallery/${image})` }}
                          >
                          </div>
                        }

                        <div className="content-award">
                          <div className="mc-custom-modal__center--window__desc">
                            <div dangerouslySetInnerHTML={{ __html: `Gift Card ${desc}` }} />
                          </div>

                          <div className="detail-award">
                            <div className="mc-custom-modal__center--window__title">{title}</div>

                          </div>
                          {/* {terms &&
                            <div className="mc-custom-modal__center--window__terms">
                              <Link to={terms.termsLink} target="_blank" rel='noopener noreferrer'>
                                {terms.termsText}
                              </Link>
                            </div>
                          } */}
                        </div>

                      </div>

                      <div className="mc-custom-modal__center--window__actions">
                        <Button
                          type="primary"
                          text={primaryAction ? primaryAction.buttonText : cta}
                          loading={primaryAction ? primaryAction.loading : false}
                          status={primaryActionStatus}
                          onClick={primaryAction ? primaryAction.action : close}
                        />
                        {secondaryAction &&
                          <Button
                            type="secondary"
                            text={secondaryAction.buttonText}
                            loading={false}
                            status="active"
                            onClick={secondaryAction.action}
                          />
                        }
                      </div>

                      <div className="bonus-reminder">
                        Recuerda, puedes descargar tu bono las veces que quieras en
                        la sección “Redenciones”
                      </div>



                    </div>
                    <div className="modal-footer">
                      <div className='text-tyc'>
                        <p>El bono está sujeto a los términos y condiciones del comercio aliado.</p>
                      </div>

                      <div className="tycDropdown">
                        <h3 onClick={handleOpenTyc}>Conócelos aquí <span className={`iconDrop ${dropTyc ? 'rotateIcon' : ''}`}><Icon name='more' /></span></h3>
                        <div className={`content-tyc ${dropTyc ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: awardSelected?.description }} />
                      </div>


                    </div>
                  </div>
                </>
                :

                globalModal ?

                  <>
                    {
                      iconClose &&
                      <div className="mc-custom-modal__close" onClick={close}>
                        <Icon name="close" />
                      </div>

                    }
                    {image &&
                      <div
                        className="mc-custom-modal__center--window__image"
                        style={{ "backgroundImage": `url(/assets/images/gallery/${image})` }}
                      >
                      </div>
                    }
                    <div className="mc-custom-modal__center--window__title">{title}</div>
                    <div className="mc-custom-modal__center--window__desc">
                      <div dangerouslySetInnerHTML={{ __html: desc }} />
                    </div>

                    {twoStepAuth &&
                      <div className="mc-custom-modal__center--window__auth">
                        <p>Doble autenticación</p>

                        <Input
                          type={'birthdate'}
                          label={'Fecha de expedición de su cédula'}
                          placeholder={'DD/MM/AAAA'}
                          onChange={handleChange}
                          inputByCharacter={false}
                          maxlength={10}
                          value={expDate}
                          error={errors.expDate}
                        />
                      </div>
                    }

                    {
                      location ?
                        <FormQuantumData
                          token={token}
                          location={location}
                          brand_id={brand_id}
                          quantumName={quantumName}
                          setQuantumName={setQuantumName}
                          quantumDoc={quantumDoc}
                          setQuantumDoc={setQuantumDoc}
                          department={department}
                          city={city}
                          site={site}
                          setDepartment={setDepartment}
                          setCity={setCity}
                          setSite={setSite}
                        />
                        :
                        <></>
                    }

                    <div className="mc-custom-modal__center--window__actions">
                      <Button
                        type="primary"
                        text={primaryAction ? primaryAction.buttonText : cta}
                        loading={primaryAction ? primaryAction.loading : false}
                        status={'active'}
                        onClick={primaryAction ? primaryAction.action : close}
                      />
                      {secondaryAction &&
                        <Button
                          type="secondary"
                          text={secondaryAction.buttonText}
                          loading={false}
                          status="active"
                          onClick={secondaryAction.action}
                        />
                      }
                    </div>
                    {terms &&
                      <div className="mc-custom-modal__center--window__terms">
                        <Link to={terms.termsLink} target="_blank" rel='noopener noreferrer'>
                          {terms.termsText}
                        </Link>
                      </div>
                    }
                  </>

                  :

                  specialAward ?
                    <>
                      <div className="modal-content">
                        <div className="modal-header">
                          <div className="mc-custom-modal__close" onClick={close}>
                            <Icon name="close" />
                          </div>
                          {image &&
                            <div
                              className="mc-custom-modal__center--window__image"
                            >
                              <img src={`/assets/images/${image}`} alt="" />
                            </div>
                          }
                          <div className="mc-custom-modal__center--window__title">{titleModal}</div>


                        </div>
                        <div className={`modal-body ${segment ? segment : '' }`}>
                          <div className="mc-custom-modal__center--window__desc">
                            <div dangerouslySetInnerHTML={{ __html: `${desc}` }} />
                          </div>

                        </div>
                        <div className="modal-footer">
                          <div className="mc-custom-modal__center--window__actions">
                            <Button
                              type="primary"
                              text={primaryAction ? primaryAction.buttonText : cta}
                              loading={primaryAction ? primaryAction.loading : false}
                              status="active"
                              onClick={primaryAction ? primaryAction.action : close}
                            />
                            {secondaryAction &&
                              <Button
                                type="secondary"
                                text={secondaryAction.buttonText}
                                loading={false}
                                status="active"
                                onClick={secondaryAction.action}
                              />
                            }
                          </div>

                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="mc-custom-modal__close" onClick={close}>
                        <Icon name="close" />
                      </div>
                      {image &&
                        <div
                          className="mc-custom-modal__center--window__image"
                          style={{ "backgroundImage": `url(/assets/images/gallery/${image})` }}
                        >
                        </div>
                      }
                      <div className="mc-custom-modal__center--window__title">{title}</div>
                      <div className="mc-custom-modal__center--window__desc">
                        <div dangerouslySetInnerHTML={{ __html: desc }} />
                      </div>

                      {twoStepAuth &&
                        <div className="mc-custom-modal__center--window__auth">
                          <p>Doble autenticación</p>

                          <Input
                            type={'birthdate'}
                            label={'Fecha de expedición de su cédula'}
                            placeholder={'DD/MM/AAAA'}
                            onChange={handleChange}
                            inputByCharacter={false}
                            maxlength={10}
                            value={expDate}
                            error={errors.expDate}
                          />
                        </div>
                      }

                      {
                        location ?
                          <FormQuantumData
                            token={token}
                            location={location}
                            brand_id={brand_id}
                            quantumName={quantumName}
                            setQuantumName={setQuantumName}
                            quantumDoc={quantumDoc}
                            setQuantumDoc={setQuantumDoc}
                            department={department}
                            city={city}
                            site={site}
                            setDepartment={setDepartment}
                            setCity={setCity}
                            setSite={setSite}
                          />
                          :
                          <></>
                      }

                      <div className="mc-custom-modal__center--window__actions">
                        <Button
                          type="primary"
                          text={primaryAction ? primaryAction.buttonText : cta}
                          loading={primaryAction ? primaryAction.loading : false}
                          status={primaryActionStatus}
                          onClick={primaryAction ? primaryAction.action : close}
                        />
                        {secondaryAction &&
                          <Button
                            type="secondary"
                            text={secondaryAction.buttonText}
                            loading={false}
                            status="active"
                            onClick={secondaryAction.action}
                          />
                        }
                      </div>
                      {terms &&
                        <div className="mc-custom-modal__center--window__terms">
                          <Link to={terms.termsLink} target="_blank" rel='noopener noreferrer'>
                            {terms.termsText}
                          </Link>
                        </div>
                      }
                    </>
            }
          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal