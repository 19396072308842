import React, { useContext, useEffect } from 'react'
import Container from '../../layouts/Container/Container'
import FaqsFeed from '../organisms/FaqsFeed'
import GoBack from '../molecules/GoBack'
import Preloader from '../atoms/Preloader'
import { StateController } from '../../App'

function Faqs() {
  const { staticContent } = useContext(StateController)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <section className='mc-page faqs slow_ani'>

      <Container>
        <article>
          <GoBack />
        </article>
        <article className='titles-faq'>
          <h1>Preguntas Frecuentes</h1>

        </article>

        <article className='content-faq'>
        {staticContent
          ? <FaqsFeed
              faqsType={1}
              list={staticContent?.faqs}
            />
          : <Preloader
              type="section"
              invert
            />
        }
        </article>

      </Container>
      
    </section>
  )
}
export default Faqs