import React from 'react'
import Icon from "../atoms/Icon";

const GoBack = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className='GoBack'>
        <button onClick={handleGoBack} className='btn btn-goback' >
          <Icon name='go-back' />
          <span className='text-button'>Regresar</span>
        </button>
      </div>
    </>
  )
}

export default GoBack
