import React, { useEffect } from 'react'
import SectionLogin from '../molecules/SectionLogin'
import SectionMechanic from '../molecules/SectionMechanic'
import Container from '../../layouts/Container/Container'
import SectionFAQS from '../molecules/SectionFAQS'
import SectionTyc from '../molecules/SectionTyc'
import SectionBenefits from '../molecules/SectionBenefits'

function Login({
  autologin,
  hash
}) {

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page login slow_ani'>
      <SectionLogin autologin={autologin} hash={hash} />
      {/* <Container className={'no-padding-slider'}> */}
        <SectionMechanic />
      {/* </Container> */}
      <SectionBenefits />
      <SectionFAQS />
      <Container className={'tyc'}>
      <SectionTyc />

      </Container>
    </div>
  )
}
export default Login