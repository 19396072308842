export const SpecialAwards = [
  {
    segment: 'Uso primario',
    premio: [
      {
        mainTitle: '¿Te imaginas explorando las maravillas del Perú? Cumple tu meta, redime tu bono, supera los retos y sé el cliente que más veces alcanzó su meta para ganar el premio mayor.',
        image: 'Gif-machupichu.gif',
        imgModal:'premioEspecial-modal-1.png',
        title: 'Gana un increíble viaje para dos:',
        description: 'Se el cliente que más veces cumple su meta y supera al menos un reto para ganar el viaje',
        btnText: '¿Qué incluye?',
        content:'<h3>Plan Experiencia Perú.</h3> <ul> <li>Cuzco.</li> <li>4 Dias 3 Noches.</li> <li>Ocupación Doblesy.</li> <li>NO require Visa.</li> <li>6 HR 20 Min en promedio.</li> <li>Anfitrión/Conserje dedicado en línea.</li> <li>Desayuno, Almuerzo or Cena incluido.</li> </ul> <h4>Itinerario Experiencia Perú.</h4> <h5>Día 1</h5> <ul> <li>Llegada a Cuzco.</li> <li>Traslado privado desde el aeropuerto a un hotel de 4 estrellas.</li> <li>Bienvenida y recepción en el hotel.</li> <li>Cena privada experiencia Perú en Chicha – Menú degustación por Gastón Acurio.</li> </ul> <h5>Día 2</h5> <ul> <li>Desayuno en el hotel.</li> <li>Tour privado por la ciudad de Cuzco.</li> <li>Servicio de coche al aeropuerto de Cuzco.</li> <li>Viaje de Cuzco a San José.</li> </ul> <h5>Día 3</h5> <ul> <li>Desayuno en el hotel.</li> <li>Tour privado a Machu Picchu.</li> <li>Tren Vistadome a Aguas Calientes.</li> <li>Cena en un hotel de 4 estrellas en Machu Picchu.</li> </ul> <h5>Día 4</h5> <ul> <li>Desayuno en el hotel.</li> <li>Tour privado a Maras, Moray y Ollantaytambo.</li> <li>Tren Vistadome a Aguas Calientes.</li> <li>Cena privada experiencia Perú a la carta en el restaurante Lima.</li> </ul>',
        titleModal:'Experiencia Perú',
        gif:true,
      }
    ]
  },
  {
    segment: 'Uso secundario',
    premio: [
      {
        mainTitle: '¿iPhone 16? ¡Uno puede ser tuyo! Cumple tu meta, redime tu bono, supera los retos y sé el cliente que más veces alcanzó su meta para ganar.',
        image: 'premioEspecial-2.png',
        imgModal:'premioEspecial-modal-2.png',
        title: 'iPhone 16 Pro',
        description: '<ul><li>Se el cliente que más veces cumple su meta y supera al menos un reto para ganar el iPhone 16 Pro MAX 256 GB</li></ul>',
        btnText: '¿Qué incluye?',
        content:'<h3>Celular iPhone 16 Pro</h3> <h4>Te contamos las características del IPhone 16 Pro</h4> <ul> <li>Capacidad de almacenamiento: 256GB.</li> <li>Conectividad: 5G.</li> <li>Marca y modelo del procesador: Chip A18 Pro.</li> <li>Sistema operativo: IOS 18.</li> <li>Memoria RAM: 8GB.</li> </ul>',
        titleModal:'iPhone 16 Pro',
        gif:false,
      }
    ]
  },
  {
    segment: 'Inactivos',
    premio: [
      {
        mainTitle: '¡Gana un bono de $200 USD para compras en Amazon! Cumple tus metas, redime tu bono y supera los retos. Sé el cliente que más veces alcanzó su meta y podrás llevártelo.',
        image: 'premioEspecial-3.png',
        imgModal:'premioEspecial-modal-3.png',
        title: 'Bonos de Amazon',
        description: 'Se el cliente que más veces cumple su meta y supera al menos un reto para ganar 2 Bonos C/U de $200 USD',
        btnText: '¿Qué incluye?',
        content:'<h3>Bono de Amazon:</h3> <p>Tu bono se añadirá automáticamente a tu saldo en Amazon. Las compras que hagas se descontarán de ese saldo, y cualquier cantidad que no uses quedará disponible en tu cuenta. Si el total de tu compra supera el saldo, puedes cubrir la diferencia con otro método de pago.</p> <p><strong>Descripción:</strong> Gift Card para compras en pagina de Amazon USA</p> <p><strong>Cobertura:</strong> Compra Online USA</p> <h4>Términos y condiciones del bono:</h4> <p>Las Tarjetas de Regalo de Amazon.com para Estados Unidos de América, que se agregan automáticamente a su Saldo de Amazon.com. Para ver su Saldo de Amazon.com, visite Su Cuenta. Las compras son deducidas de su Saldo de Amazon.com . Cualquier Saldo de Amazon.com no usado permanecerá asociado con su cuenta de Amazon.com. Si una compra excede su Saldo de Amazon.com, el monto pendiente debe ser pagado con otro método de pago. <a href="https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990" target="_blank" rel="noopener noreferrer">https://www.amazon.com/-/es/gp/help/customer/display.html?nodeId=201936990.</a></p> <h4>Forma de redención: Virtual en amazon.com</h4> <p><strong>Proceso de Canje:</strong></p> <ul> <li>1. Visita amazon.com.</li> <li>2. Regístrate o inicia sesión.</li> <li>3. En el momento del pago. Ingresa el código y selecciona el botón Canjear.</li> <li>4. Finaliza la compra.</li> </ul>',
        titleModal:'Bonos de Amazon',
        gif:false,
      }
    ]
  },
];
