import React from 'react'

import Icon from './Icon'

function ProgressBar({
  title,
  percent, 
  label,
  icon,
  middle,
  dashboard
}) {
  const getIconProgress = (percentage) =>{
    if (percentage < 50) {
      return '0.png'
      
    }else if(percent >= 50 && percent < 100){
      return '50.png'
      
    }else if(percent >= 100){
      return '100.png'
      
    }    
    else{
      return '0.png'      
    }
  }
  return (
    <div className={`mc-progressBar-container ${title ? 'has-title' : ''} ${middle ? 'col-2' : 'col-1'}`}>    
        <img style={{left: `${percent < 50 ? 0 : (percent >= 50 && percent < 100) ? 50-10 : percent >= 100 ? 84 : percent}%`}} className='iconSpan' src={`/assets/images/${getIconProgress(percent)}`} />       
      {title &&
        <div className='mc-progressBar-title'>
          {title}
        </div>
      }
      {dashboard &&
        <span className='mc-progressBar-label'>{`${percent}%`} {dashboard}</span>
      }
      <div className="mc-progressBar-progress" style={{"width": `${percent < 10 ? 10 : percent}%`}}></div>
      <div className={`mc-progressBar-percent ${percent > 52 ? 'white' : ''}`}>
        {icon &&
          <Icon name={icon} />
        }
        {label && label}
      </div>
    </div>
  )
}

export default ProgressBar