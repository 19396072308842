import React from 'react'
import Container from '../../layouts/Container/Container'

const SectionBenefits = () => {
  return (
    <section className='SectionBenefits'>
      <Container>
        <article className='art-title'>
          <h2>Beneficios para ti, si decides ganar usando tu tarjeta</h2>
        </article>
        <article className='content-benefits'>
          
          <ul>
            <li>Compra de manera segura por internet o inscríbela para pagar tus plataformas favoritas.</li>
            <li>Mastercard Global Services: Aprovecha seguros, asistencias y asesorías en el exterior y Mastercard Travel rewards.</li>
            <li>Acceso gratuito a PacifiCard Box: Recibe tus compras en una casilla en Miami sin costo.</li>
            <li>Difiere tus compras hasta en 48 meses.</li>
          </ul>
        </article>
      </Container>
    </section>
  )
}

export default SectionBenefits
