import React from 'react'
import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'

const SectionAwards = ({ awards, canRedem }) => {
    return (
        <section className='SectionAwards'>
            <Container>
                <article className='title-section-awards'>                    
                    <h2>Catálogo de premios</h2>
                    <p className='desc-awards'>Conoce los bonos que tenemos para ti, elige tu favorito y redímelo.</p>
                </article>
                <article className='awards-all-items'>
                    {awards
                        ? <AwardsGallery
                            awardBoxType={2}
                            itemsPerLine={3}
                            limit={false}
                            limitButtonText="Ver más comercios"
                            // showStock={true}
                            list={awards}
                            steps={false}
                            canRedem={canRedem}
                            twoStepAuth={false}
                            redemtionText="Redimir bono"
                        />
                        : <Preloader
                            type="section"
                            invert
                        />
                    }
                </article>
            </Container>
        </section>
    )
}

export default SectionAwards
