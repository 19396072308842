import React, { useContext, useEffect, useState } from 'react'
import Container from "../../layouts/Container/Container";
import Columns, {Col} from "../../layouts/Columns/Columns";
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter';
import Button from '../atoms/Button';
import {SpecialAwards} from "../../data/SpecialAwards";
import { StateController } from '../../App';
import CustomModal from './CustomModal';
import { sendDataLayer } from '../../utils/functions';

const SectionPremioMayor = () => {
  const [allSpecialAwrds, setAllSpecialAwrds] = useState([]);
  const {context} = useContext(StateController)
  const [modalSpecialAwards, setModalSpecialAwards] = useState(false);
  
  const openCloseModalSpecialAwards = ()=>{
    setModalSpecialAwards(!modalSpecialAwards)
    sendDataLayer({ datalayer: { event: "jackpot", text: allSpecialAwrds[0]?.premio[0]?.btnText, link: ''}, repeat: true })
  }
  
  useEffect(() => {
    if (context && context.user) {
      const data = SpecialAwards.filter(award => award.segment === context.user.segment)
      setAllSpecialAwrds(data)
    }
  }, [context])

  return (
    <>
    <section className='SectionPremioMayor'>
      <Container>
        <article className='section-title'>
          <h3 dangerouslySetInnerHTML={{__html: allSpecialAwrds[0]?.premio[0]?.mainTitle}}>
            
          </h3>
        </article>

        <article>
          <Columns desktop={2} mobile={1}>
            <Col>
              <div onClick={openCloseModalSpecialAwards} className={`img-spacial-award ${allSpecialAwrds[0]?.premio[0]?.gif ? 'gif' : ''}`}>
                <img src={`/assets/images/${allSpecialAwrds[0]?.premio[0]?.image}`} alt="" />
              </div>
            </Col>
            <Col>
              <div className='content-info'>
                <VerticalCenter>
                  <h2>
                  {allSpecialAwrds[0]?.premio[0]?.title}
                  </h2>
                  <p className='desc-special-award' dangerouslySetInnerHTML={{__html: allSpecialAwrds[0]?.premio[0]?.description}}>
                  
                  </p>

                  <div className='btn-special-award' onClick={()=>sendDataLayer({ datalayer: { event: "jackpot", text: allSpecialAwrds[0]?.premio[0]?.btnText, link: ''}, repeat: true })}>
                    <Button 
                       type='auxiliar'
                       text={allSpecialAwrds[0]?.premio[0]?.btnText}
                       status='active'                       
                       className={'fit-content'}  
                       onClick={openCloseModalSpecialAwards}   
                       loading={modalSpecialAwards ? true : false}                 
                    />
                  </div>

                </VerticalCenter>
              </div>
            </Col>
          </Columns>
        </article>
      </Container>
    </section>
    
    <CustomModal
        segment={context?.user?.segment}
        specialAward
        image={allSpecialAwrds[0]?.premio[0]?.imgModal}
        title={allSpecialAwrds[0]?.premio[0]?.title}
        titleModal={allSpecialAwrds[0]?.premio[0]?.titleModal}
        desc={allSpecialAwrds[0]?.premio[0]?.content}
        cta="Aceptar"        
        show={modalSpecialAwards} setShowModal={setModalSpecialAwards}
      />
    </>
  )
}

export default SectionPremioMayor
