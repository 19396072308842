import React from 'react'
import Button from '../atoms/Button'
// import Container from '../../layouts/Container/Container'
import { sendDataLayer } from '../../utils/functions'

const SectionTyc = () => {
  const datalayerGotTyc = () =>{
    sendDataLayer({ datalayer: { event: "footer", text: "Consultar", link: "/terminos-y-condiciones" }, repeat: true })
  }
  return (
    <section className='SectionTyc'>
      
        <article>
          <div className='content-tyc'>
            <h4>
              Términos y condiciones
            </h4>
            <div onClick={datalayerGotTyc}>
              <Button
                type={'secondary'}
                text={<>Consultar</>}
                loading={''}
                status={'active'}
                link={'/terminos-y-condiciones'}   
                className='btn-section-tyc'             
              />
            </div>
          </div>
        </article>
      
    </section>
  )
}

export default SectionTyc
