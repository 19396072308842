import React from 'react'

function CardBox({
  image,
  title,
  desc
}) {

  return (
    <div className={`mc-cardbox-container`}>
      {image &&
        <img src={`/assets/images/${image}`} alt="Step image" />
      }
      {title &&
        <h5>{title}</h5>
      }
      {desc &&
        <div dangerouslySetInnerHTML={{ __html: desc }} />
      }
    </div>
  )
}

export default CardBox