import React, { useContext, useEffect, useState } from 'react'
import SectionProgress from '../molecules/SectionProgress'
import BonusReminder from '../molecules/BonusReminder'
import SectionRetos from '../molecules/SectionRetos'
import SectionTyc from '../molecules/SectionTyc'
import SectionPremioMayor from '../molecules/SectionPremioMayor'
import { StateController } from '../../App'
import { getMaximumBlock, sendDataLayer } from '../../utils/functions'
import Preloader from '../atoms/Preloader'
import Container from '../../layouts/Container/Container'
import CustomModal from '../molecules/CustomModal'

function Progress() {
  const [canRedem, setCanRedem] = useState(false)
  const { context } = useContext(StateController)
  const [modalFirstTime, setModalFirstTime] = useState(false);
  const [modalAlreadyRegistered, setModalAlreadyRegistered] = useState(false);
  const [modalBonusAvailable, setModalBonusAvailable] = useState(false);


  
  const handleCloseModal = () => {
    setModalFirstTime(false);    
    if (context && context.firstTime) {
      localStorage.setItem("success_registration", "true");
      
    }
  };

  const handleCloseModalAlreadyRegistered = () => {
    setModalAlreadyRegistered(false);    
    
    if (context && context.totalLogins === 2) {
      localStorage.setItem("AlreadyRegistered", "true");
    }
  };


  useEffect(() => {
    const success_registration = localStorage.getItem("success_registration");
    if (!success_registration && context && context?.firstTime) {
      setModalFirstTime(true);
      sendDataLayer({datalayer: { event: "success_registration", userId: context.user?.idmask, segment: context.user?.segment }, repeat: true})
    }
  }, [context]);

  useEffect(() => {
    const AlreadyRegisteredModal = localStorage.getItem("AlreadyRegistered");
    if (!AlreadyRegisteredModal && context && context.totalLogins == 2) {
      setModalAlreadyRegistered(true);
      sendDataLayer({datalayer: { event: "code_already_registered", userId: context.user?.idmask, segment: context.user?.segment }, repeat: true})
    }
  }, [context]);


  useEffect(() => {
    if (context && context.awards) {
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
    }
  }, [context])
  return (
    <div className='mc-page progress slow_ani'>
      {
        context ?

          <>
            <SectionProgress />
            {
              (context && canRedem) && <BonusReminder />
            }
            <SectionRetos />
            <SectionPremioMayor />
            <Container className={'tyc'}>
              <SectionTyc />

            </Container>
            {
              (modalFirstTime) &&
              <CustomModal
                globalModal
                title="Tu código fue registrado con éxito"
                desc={`Revisa la meta que enviamos a tu correo electrónico, cúmplela y regresa para Canjear tu premio.`}
                cta="Aceptar"
                show={modalFirstTime} setShowModal={handleCloseModal}
              />
            }
            {
              (modalAlreadyRegistered) &&
              <CustomModal
                globalModal
                title="¡Este código ya está registrado!"
                desc={`Ya estás participando en Decide Ganar. Continúa comprando con tu Mastercard PacifiCard.`}
                cta="Aceptar"
                show={modalAlreadyRegistered} setShowModal={handleCloseModalAlreadyRegistered}
              />
            }

            {
              (context && canRedem) &&
              <CustomModal
                globalModal
                title={`Tienes un bono de USD$${(context && context.user[`award_${context.block}`]) ? context && context.user[`award_${context.block}`] : '' } disponible para Canjear`}
                desc={`Consulta los bonos disponibles, elige el de tu marca favorita y disfrútalo.`}
                cta="Aceptar"
                show={modalBonusAvailable} setShowModal={setModalBonusAvailable}
              />
            }
          </>
          :
          <Preloader type={'fullscreen'} />
      }
    </div>
  )
}
export default Progress