import React from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import LoginForm from '../organisms/LoginForm'

const SectionLogin = ({hash, autologin}) => {
  return (
    <section style={{ backgroundImage: `url('/assets/images/banner-hero/bannerHeroLogin.png')` }} className='SectionLogin'>
      <Container>
        <Columns>
          <Col>
            <article className='login-content-text'>
              <h1>¡Decide ganar <br />
                ya está aquí!</h1>
              <p className='login-text1'>
                Haz todas tus compras con tu Tarjeta de Crédito Mastercard PacifiCard, cumple tu meta y disfruta de un bono digital y grandes premios.
              </p>
              <p className='login-text2'>
                Revisa tu bandeja de entrada o spam, y/o SMS, para encontrar tu código de ingreso.
              </p>
            </article>
          </Col>
          <Col>
            <article>

              <LoginForm
                // title="Título corto"
                type="credentials"
                fields={[
                  { field: "code", label: "", placeholder: "XHL123...", maxlength: 8, separated: false },
                  { field: "terms", label: "He leído y acepto los", termsText: "Términos y condiciones", termsLink: "/terminos-y-condiciones" } // DO NOT CHANGE THIS LINE
                ]}
                buttonText="Ingresar"
                background="#fff"
                autologin={autologin}
                hash={hash}
              />
            </article>
          </Col>
        </Columns>
      </Container>
    </section>
  )
}

export default SectionLogin
