import React from 'react'

import SliderRotator from '../organisms/SliderRotator'
import useWindowResizer from "../../utils/useWindowResizer";
import Container from '../../layouts/Container/Container';
const SectionMechanic = () => {
  const { width } = useWindowResizer()  
  return (
    <section className='SectionMechanic'>
      <Container>
        <article>
          <h2>¿Cómo ganar?</h2>
        </article>
        <article>
          <div className='slider-mechanic'>            

            <SliderRotator
              centerPadding='0px 0px'
              centerMode={width < 992 ? true : false}
              mobileItemsToShow={1}
              desktopItemsToShow={3}
              list={
                [
                  {
                    id: 1,
                    image: "step-1.png",
                    title: "Regístrate",
                    desc: "Usa el código que te enviamos por correo o mensaje de texto y prepárate para comenzar"
                  },
                  {
                    id: 2,
                    image: "step-2.png",
                    title: "Descubre tu meta",
                    desc: "Conoce cuál es tu objetivo y el bono que puedes ganar comprando con tu Mastercard PacifiCard desde el 11 de noviembre de 2024 hasta el 5 de enero de 2025"
                  },
                  {
                    id: 3,
                    image: "step-3.png",
                    title: "Cumple y supera tu meta",
                    desc: "Cuantas más veces cumplas tu meta y superes al menos un reto, tendrás más posibilidades de llevarte un premio mayor. ¡Es tu momento, decide ganar!"
                  },
                ]
              }
            />            
          </div>
        </article>
      </Container>
    </section>
  )
}

export default SectionMechanic
