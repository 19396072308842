import React, { useContext, useEffect, useState } from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import ProgressBar from "../atoms/ProgressBar";
import Icon from '../atoms/Icon';
import Button from '../atoms/Button';
import { StateController } from '../../App';
import { sendDataLayer } from '../../utils/functions';

const SectionProgress = () => {
  const {context} = useContext(StateController)
  const [porcentaje, setPorcentaje] = useState(0);

  useEffect(() => {
    
    const percAmount =
      (
        context.tracing ? ((context?.tracing[`amount_${context?.block === 2 ? 1 : context?.block}`] / context?.user[`goal_amount_${context?.block === 2 ? 1 : context?.block}`]) * 100) : 0
      ).toFixed(1)
    let perc = percAmount > 100 ? 100 : percAmount
    setPorcentaje(perc)
  }, [context]);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])
  return (
    <section style={{ backgroundImage: `url('/assets/images/banner-hero/bannerHeroProgress.png')` }} className='SectionProgress'>
      <Container>
        <Columns>
          <Col>
            <article className='content-progress'>              
              <h2>Meta y progreso</h2>
              <p className="desc-progress">
                ¿Qué tan cerca estás de llegar <br />
                a tu meta y ganar?
              </p>
            </article>

          </Col>
          <Col>
            <article className='info-progress'>
              <div className='progress-component'>
                <div className="info-meta">
                  <p><Icon name="icon-dollar" /> <strong>Tu meta: <span>{`USD$${(context && context?.user) ? context?.user[`goal_amount_${context?.block === 2 ? 1 : context?.block}`] : 0}`}</span></strong></p>
                </div>

                <div className="progress-amount">
                  <p>
                    Llevas <br />
                    {`USD$${(context && context?.tracing) ? context?.tracing[`amount_${context?.block === 2 ? 1 : context?.block}`] : 0}`}
                  </p>
                  <div className='bar-content-progress'>
                    <ProgressBar
                      percent={porcentaje}
                      // label={`10/25`}
                    />
                  </div>
                </div>

                <div className="line-progress"></div>

                <div className="date-update">
                  <p>Última actualización de tus consumos: <span>{(context && context?.tracing) ? context?.tracing?.date_update : 'Updating...'}</span></p>
                </div>

                <div onClick={() => sendDataLayer({ datalayer: { event: "progress", text: "Conocer los premios", link: "/premios" }, repeat: true })} className="btn-progress">
                  <Button
                    type={'secondary'}
                    text={'Conocer los premios'}
                    icon='cup'
                    link={'/premios'}
                    status={'active'}
                  />
                </div>
              </div>
            </article>

          </Col>
        </Columns>
      </Container>
    </section>
  )
}

export default SectionProgress
