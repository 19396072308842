import React, { useContext, useEffect, useState } from 'react'
// import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
// import draftToHtml from 'draftjs-to-html'

import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import GoBack from '../molecules/GoBack'
import Preloader from '../atoms/Preloader'
// import Preloader from '../atoms/Preloader'

function Terms() {
  const { staticContent } = useContext(StateController)
  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // )
  const [termsHTML, setTermsHTML] = useState(false)  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (staticContent && staticContent.terms && staticContent.terms[0].content) {
      setTermsHTML(staticContent.terms[0].content)
    }
  }, [staticContent])

  return (
    <section className='mc-page terms slow_ani'>

      <Container>
        <article>
          <GoBack />
        </article>
        <article className='titles-tyc'>
          <h1>Términos y condiciones</h1>
          <p>
            “Decide ganar 2024 – Ola 2”
          </p>
        </article>        
          {termsHTML
            ? <div className='mc-static-content'>
              <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
            </div>
            : <Preloader
              type="section"
              invert
            />
          }        
      </Container>

    </section>
  )
}
export default Terms