import React from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'

const SectionRedemptions = ({
  // context,
  redemtions
}) => {
  return (
    <section style={{ backgroundImage: `${(redemtions && redemtions.length > 0) ? 'url("/assets/images/banner-hero/bannerHeroRedemptions.png")' : ' linear-gradient(90deg, #002A64 23.44%, #005EB8 107.19%)'}` }} className='SectionRedemptions'>
      <Container>
        <Columns desktop={redemtions && !redemtions.length > 0 ? 2 : 1}>
          <Col>
            <article className='content-redemptions'>              
              <h2>Tu redención</h2>
              <p className='desc-redemptions'>{
                  redemtions.length > 0 ?
                    <>Aquí encontrarás el bono que elegiste <br /> para disfrutar.</>
                    :
                    <>Aquí encontrarás el bono para disfrutar <br /> cuando lo redimas.</>
                }</p>

              <div>
                {redemtions
                  ? <AwardsGallery
                    variant
                    awardBoxType={2}
                    itemsPerLine={3}
                    list={redemtions}
                    showStock={false}
                    canRedem={true}
                    download={true}
                    redemtionText="Descargar de nuevo"

                  />
                  : <Preloader
                    type="fullscreen"
                    invert
                  />
                }
              </div>
            </article>

          </Col>
          <Col>
            {
              (redemtions && !redemtions.length > 0) &&
              <article className='info-section-redemptions'>
              <VerticalCenter>
                <div className='img-not-redeem'>
                  <img src="/assets/images/ticket.png" />
                </div>
                <div style={{ maxWidth: '253px' }} className='btnNotRedeem'>
                  <Button type={'secondary'} link={'/premios'} status={'active'} text={'Ir a premios'} />
                </div>
              </VerticalCenter>
            </article>


            }

          </Col>
        </Columns>
      </Container>
    </section>
  )
}

export default SectionRedemptions
