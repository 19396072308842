import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'
import SectionRedemptions from '../molecules/SectionRedemptions'
import SectionPremioMayor from '../molecules/SectionPremioMayor'
import SectionTyc from '../molecules/SectionTyc'
import Preloader from '../atoms/Preloader'
import Container from '../../layouts/Container/Container'
import { getMaximumBlock } from '../../utils/functions'
import BonusReminder from '../molecules/BonusReminder'

function Redemptions() {
  const { context } = useContext(StateController)
  const [redemtions, setRedemtions] = useState(false)
  const [canRedem, setCanRedem] = useState(false)

  useEffect(() => {
    if (context && context.redemtions) {
      setRedemtions(context.redemtions)
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page redemptions slow_ani'>

      {
        context ?
          <>
            <SectionRedemptions context={context} redemtions={redemtions} />

            <SectionPremioMayor />
            <Container className={'tyc'}>
              <SectionTyc />

            </Container>
            {
              (context && canRedem) && <BonusReminder />
            }
          </>

          :

          <Preloader type={'fullscreen'} />
      }
    </div>
  )
}
export default Redemptions