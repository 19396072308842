import React from 'react'

function Icon(props) {

  return (
    <div className={`mc-icon ${props.className}`}>
      {props.name === "test" &&
        <span className={`icon-${props.name}`}>
          <img src={`/assets/images/${props.name}.png`} alt='Boton Test' />
        </span>
      }
      {props.name === "icon" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.103 2H14.897C15.2911 1.99995 15.6814 2.07756 16.0455 2.22838C16.4096 2.3792 16.7404 2.60029 17.019 2.879L21.12 6.979C21.3993 7.25791 21.6207 7.58917 21.7717 7.95382C21.9227 8.31846 22.0003 8.70932 22 9.104V14.898C22 15.2921 21.9224 15.6824 21.7716 16.0465C21.6208 16.4106 21.3997 16.7414 21.121 17.02L17.021 21.121C16.7423 21.4 16.4114 21.6214 16.0471 21.7724C15.6828 21.9233 15.2923 22.001 14.898 22.001H9.103C8.70883 22.0009 8.31854 21.9232 7.95443 21.7722C7.59033 21.6212 7.25955 21.3999 6.981 21.121L2.88 17.021C2.60087 16.7422 2.37946 16.4111 2.22846 16.0466C2.07746 15.6822 1.99982 15.2915 2 14.897V9.103C1.99995 8.7089 2.07756 8.31865 2.22838 7.95455C2.3792 7.59045 2.60029 7.25963 2.879 6.981L6.979 2.88C7.25791 2.60075 7.58917 2.37927 7.95382 2.22827C8.31846 2.07726 8.70932 1.99969 9.104 2H9.103Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16V16.01" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13C12.4498 13.0014 12.8868 12.8511 13.2407 12.5734C13.5945 12.2958 13.8444 11.907 13.95 11.4698C14.0557 11.0327 14.0109 10.5726 13.8229 10.1641C13.6349 9.75549 13.3147 9.42219 12.914 9.21801C12.5162 9.01422 12.0611 8.95103 11.6228 9.03873C11.1845 9.12642 10.7888 9.35984 10.5 9.70101" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "close" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M1.31728 16.6346L0 15.3173L7 8.31728L0 1.31728L1.31728 0L8.31728 7L15.3173 0L16.6346 1.31728L9.63456 8.31728L16.6346 15.3173L15.3173 16.6346L8.31728 9.63456L1.31728 16.6346Z" fill="#2B3550" />
          </svg>
        </span>
      }
      {props.name === "faq" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="9" viewBox="0 0 18 9" fill="none">
            <path d="M1 1L9 7.4L17 1" stroke="#040303" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "close_session" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.6665 5.33335V4.00002C6.6665 3.6464 6.80698 3.30726 7.05703 3.05721C7.30708 2.80716 7.64622 2.66669 7.99984 2.66669H12.6665C13.0201 2.66669 13.3593 2.80716 13.6093 3.05721C13.8594 3.30726 13.9998 3.6464 13.9998 4.00002V12C13.9998 12.3536 13.8594 12.6928 13.6093 12.9428C13.3593 13.1929 13.0201 13.3334 12.6665 13.3334H7.99984C7.64622 13.3334 7.30708 13.1929 7.05703 12.9428C6.80698 12.6928 6.6665 12.3536 6.6665 12V10.6667" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 8H2L4 6" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 10L2 8" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "menu" &&
        <span className={`icon-${props.name}`}>
          <svg width="40" height="29" viewBox="0 0 40 29" fill="none">
            <rect y="0.5" width="40" height="4" fill="white" />
            <rect y="12.5" width="40" height="4" fill="white" />
            <rect y="24.5" width="40" height="4" fill="white" />
          </svg>
        </span>
      }
      {props.name === "arrow-link" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.267 4.4545C12.7063 4.01517 13.4187 4.01517 13.858 4.4545L20.608 11.2045C21.0473 11.6438 21.0473 12.3562 20.608 12.7955L13.858 19.5455C13.4187 19.9848 12.7063 19.9848 12.267 19.5455C11.8277 19.1062 11.8277 18.3938 12.267 17.9545L18.2215 12L12.267 6.0455C11.8277 5.60616 11.8277 4.89384 12.267 4.4545Z" fill="#005EB8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.0625 12C4.0625 11.3787 4.56618 10.875 5.1875 10.875H18.875C19.4963 10.875 20 11.3787 20 12C20 12.6213 19.4963 13.125 18.875 13.125H5.1875C4.56618 13.125 4.0625 12.6213 4.0625 12Z" fill="#005EB8" />
          </svg>
        </span>
      }
      {props.name === "icon-dollar" &&
        <span className={`icon-${props.name}`}>
          <img src="/assets/images/Icon-dollar.png" alt="" />
        </span>
      }
      {props.name === "cup" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 22.25C8 21.8358 8.33579 21.5 8.75 21.5H16.25C16.6642 21.5 17 21.8358 17 22.25C17 22.6642 16.6642 23 16.25 23H8.75C8.33579 23 8 22.6642 8 22.25Z" fill="#333333" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 15.5C12.9142 15.5 13.25 15.8358 13.25 16.25V22.25C13.25 22.6642 12.9142 23 12.5 23C12.0858 23 11.75 22.6642 11.75 22.25V16.25C11.75 15.8358 12.0858 15.5 12.5 15.5Z" fill="#333333" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.7444 2C18.1422 2 18.5238 2.15804 18.8051 2.43934C19.0863 2.72057 19.2443 3.10199 19.2444 3.49971C19.2444 3.4998 19.2444 3.4999 19.2444 3.5H18.4944L19.2444 3.49882C19.2444 3.49911 19.2444 3.49941 19.2444 3.49971C19.2463 4.69036 19.25 8.62642 19.25 11C19.25 12.8412 18.0697 14.3489 16.7427 15.353C15.4173 16.3559 13.7618 17 12.4982 17C11.2345 17 9.57943 16.3559 8.25449 15.3529C6.9279 14.3487 5.74817 12.841 5.74817 11C5.74817 10.2819 5.74969 8.76716 5.75122 7.25346L5.75122 7.25276C5.75274 5.7388 5.75426 4.22597 5.75426 3.50984L5.75427 3.5075C5.75551 3.11049 5.91409 2.73016 6.19526 2.44987C6.4763 2.1697 6.85691 2.01233 7.25374 2.01219L7.25339 2.01219L7.25426 2.76219L7.25427 2.01219L17.7444 2ZM17.7444 3.5L7.25426 3.51219C7.25426 4.22998 7.25274 5.74141 7.25122 7.25271L7.25122 7.25497C7.24969 8.76917 7.24817 10.2828 7.24817 11C7.24817 12.1721 8.01375 13.2894 9.15982 14.1569C10.3075 15.0257 11.6524 15.5 12.4982 15.5C13.344 15.5 14.6894 15.0257 15.8376 14.1568C16.9842 13.2892 17.75 12.1719 17.75 11C17.75 8.62667 17.7463 4.69004 17.7444 3.50118L17.7444 3.5Z" fill="#333333" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2 5C2 4.58579 2.33579 4.25 2.75 4.25H6.5C6.91421 4.25 7.25 4.58579 7.25 5C7.25 5.41421 6.91421 5.75 6.5 5.75H3.5C3.5 6.90413 3.85361 8.06912 4.43078 8.92701C5.01071 9.78902 5.7442 10.25 6.5 10.25C6.91421 10.25 7.25 10.5858 7.25 11C7.25 11.4142 6.91421 11.75 6.5 11.75C5.07846 11.75 3.93695 10.8802 3.18622 9.76431C2.43272 8.64432 2 7.18431 2 5.75V5Z" fill="#333333" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.75 5C17.75 4.58579 18.0858 4.25 18.5 4.25H22.25C22.6642 4.25 23 4.58579 23 5V5.75C23 7.18431 22.5673 8.64432 21.8138 9.76431C21.0631 10.8802 19.9215 11.75 18.5 11.75C18.0858 11.75 17.75 11.4142 17.75 11C17.75 10.5858 18.0858 10.25 18.5 10.25C19.2558 10.25 19.9893 9.78902 20.5692 8.92701C21.1464 8.06912 21.5 6.90413 21.5 5.75H18.5C18.0858 5.75 17.75 5.41421 17.75 5Z" fill="#333333" />
          </svg>
        </span>
      }
      {props.name === "close-btn" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z" fill="#F0F7FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803C5.92678 17.4874 5.92678 17.0126 6.21967 16.7197L16.7197 6.21967C17.0126 5.92678 17.4874 5.92678 17.7803 6.21967Z" fill="#F0F7FF" />
          </svg>
        </span>
      }
      {props.name === "challenger-failed" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <mask id="mask0_5988_2061" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
              <rect x="0.5" y="0.5" width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_5988_2061)">
              <path d="M8.49998 9.3501L10.4333 11.2834C10.5555 11.4057 10.6972 11.464 10.8583 11.4584C11.0194 11.4529 11.1611 11.389 11.2833 11.2668C11.4055 11.1445 11.4666 11.0029 11.4666 10.8418C11.4666 10.6807 11.4055 10.5392 11.2833 10.4175L9.34998 8.49176L11.2833 6.56605C11.4055 6.4443 11.4666 6.30288 11.4666 6.14176C11.4666 5.98065 11.4055 5.83899 11.2833 5.71676C11.1611 5.59454 11.0194 5.53343 10.8583 5.53343C10.6972 5.53343 10.5555 5.59454 10.4333 5.71676L8.49998 7.6501L6.56664 5.71676C6.44442 5.59454 6.30553 5.53343 6.14998 5.53343C5.99442 5.53343 5.85553 5.59454 5.73331 5.71676C5.61109 5.83899 5.54998 5.98065 5.54998 6.14176C5.54998 6.30288 5.6106 6.44454 5.73186 6.56676L7.64998 8.5001L5.71664 10.4334C5.59442 10.5557 5.53609 10.6945 5.54164 10.8501C5.5472 11.0057 5.61109 11.1445 5.73331 11.2668C5.85553 11.389 5.9972 11.4501 6.15831 11.4501C6.31942 11.4501 6.4606 11.3895 6.58186 11.2682L8.49998 9.3501ZM8.50458 14.9001C7.62373 14.9001 6.79442 14.7334 6.01664 14.4001C5.23886 14.0668 4.55831 13.6084 3.97498 13.0251C3.39164 12.4418 2.93331 11.7614 2.59998 10.9841C2.26664 10.2068 2.09998 9.37625 2.09998 8.49246C2.09998 7.60866 2.26664 6.78065 2.59998 6.00843C2.93331 5.23621 3.39164 4.55843 3.97498 3.9751C4.55831 3.39176 5.23863 2.93343 6.01594 2.6001C6.79326 2.26676 7.62382 2.1001 8.50761 2.1001C9.39141 2.1001 10.2194 2.26676 10.9916 2.6001C11.7639 2.93343 12.4416 3.39176 13.025 3.9751C13.6083 4.55843 14.0666 5.23745 14.4 6.01216C14.7333 6.78689 14.9 7.61466 14.9 8.4955C14.9 9.37634 14.7333 10.2057 14.4 10.9834C14.0666 11.7612 13.6083 12.4418 13.025 13.0251C12.4416 13.6084 11.7626 14.0668 10.9879 14.4001C10.2132 14.7334 9.38541 14.9001 8.50458 14.9001Z" fill="#E20518" />
            </g>
          </svg>
        </span>
      }
      {props.name === "challenger-success" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <mask id="mask0_5988_2095" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
              <rect x="0.5" y="0.5" width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_5988_2095)">
              <path d="M7.64998 9.2001L6.64998 8.21676C6.52775 8.09454 6.38886 8.03343 6.23331 8.03343C6.07775 8.03343 5.93886 8.09454 5.81664 8.21676C5.69442 8.33899 5.63331 8.48065 5.63331 8.64177C5.63331 8.80288 5.69442 8.94454 5.81664 9.06676L7.23331 10.4834C7.35553 10.6057 7.49442 10.6668 7.64998 10.6668C7.80553 10.6668 7.94442 10.6057 8.06664 10.4834L11.1833 7.36676C11.3055 7.24454 11.3666 7.10288 11.3666 6.94176C11.3666 6.78065 11.3055 6.63899 11.1833 6.51676C11.0611 6.39454 10.9222 6.33343 10.7666 6.33343C10.6111 6.33343 10.4722 6.39454 10.35 6.51676L7.64998 9.2001ZM8.49998 14.9001C7.6222 14.9001 6.79442 14.7334 6.01664 14.4001C5.23886 14.0668 4.55831 13.6084 3.97498 13.0251C3.39164 12.4418 2.93331 11.7612 2.59998 10.9834C2.26664 10.2057 2.09998 9.37788 2.09998 8.5001C2.09998 7.61121 2.26664 6.78065 2.59998 6.00843C2.93331 5.23621 3.39164 4.55843 3.97498 3.9751C4.55831 3.39176 5.23886 2.93343 6.01664 2.6001C6.79442 2.26676 7.6222 2.1001 8.49998 2.1001C9.38886 2.1001 10.2194 2.26676 10.9916 2.6001C11.7639 2.93343 12.4416 3.39176 13.025 3.9751C13.6083 4.55843 14.0666 5.23621 14.4 6.00843C14.7333 6.78065 14.9 7.61121 14.9 8.5001C14.9 9.37788 14.7333 10.2057 14.4 10.9834C14.0666 11.7612 13.6083 12.4418 13.025 13.0251C12.4416 13.6084 11.7639 14.0668 10.9916 14.4001C10.2194 14.7334 9.38886 14.9001 8.49998 14.9001Z" fill="#07254A" />
            </g>
          </svg>
        </span>
      }
      {props.name === "download-award" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 17.5C5.5 18.6046 6.39543 19.5 7.5 19.5H17.5C18.6046 19.5 19.5 18.6046 19.5 17.5V16.5C19.5 15.9477 19.9477 15.5 20.5 15.5C21.0523 15.5 21.5 15.9477 21.5 16.5V17.5C21.5 19.7091 19.7091 21.5 17.5 21.5H7.5C5.29086 21.5 3.5 19.7091 3.5 17.5L3.5 16.5C3.5 15.9477 3.94772 15.5 4.5 15.5C5.05228 15.5 5.5 15.9477 5.5 16.5V17.5ZM7.79289 11.7929C8.18342 11.4024 8.81658 11.4024 9.20711 11.7929L11.5 14.0858L11.5 4.5C11.5 3.94772 11.9477 3.5 12.5 3.5C13.0523 3.5 13.5 3.94772 13.5 4.5L13.5 14.0858L15.7929 11.7929C16.1834 11.4024 16.8166 11.4024 17.2071 11.7929C17.5976 12.1834 17.5976 12.8166 17.2071 13.2071L13.2071 17.2071C12.8166 17.5976 12.1834 17.5976 11.7929 17.2071L7.79289 13.2071C7.40237 12.8166 7.40237 12.1834 7.79289 11.7929Z" fill="#F0F7FF" />
          </svg>
        </span>
      }
      {props.name === "download-award-dark" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 17.5C5.5 18.6046 6.39543 19.5 7.5 19.5H17.5C18.6046 19.5 19.5 18.6046 19.5 17.5V16.5C19.5 15.9477 19.9477 15.5 20.5 15.5C21.0523 15.5 21.5 15.9477 21.5 16.5V17.5C21.5 19.7091 19.7091 21.5 17.5 21.5H7.5C5.29086 21.5 3.5 19.7091 3.5 17.5L3.5 16.5C3.5 15.9477 3.94772 15.5 4.5 15.5C5.05228 15.5 5.5 15.9477 5.5 16.5V17.5ZM7.79289 11.7929C8.18342 11.4024 8.81658 11.4024 9.20711 11.7929L11.5 14.0858L11.5 4.5C11.5 3.94772 11.9477 3.5 12.5 3.5C13.0523 3.5 13.5 3.94772 13.5 4.5L13.5 14.0858L15.7929 11.7929C16.1834 11.4024 16.8166 11.4024 17.2071 11.7929C17.5976 12.1834 17.5976 12.8166 17.2071 13.2071L13.2071 17.2071C12.8166 17.5976 12.1834 17.5976 11.7929 17.2071L7.79289 13.2071C7.40237 12.8166 7.40237 12.1834 7.79289 11.7929Z" fill="#07254A" />
          </svg>
        </span>
      }
      {props.name === "go-back" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.233 4.4545C12.6723 4.89384 12.6723 5.60616 12.233 6.0455L6.27849 12L12.233 17.9545C12.6723 18.3938 12.6723 19.1062 12.233 19.5455C11.7937 19.9848 11.0813 19.9848 10.642 19.5455L3.892 12.7955C3.45267 12.3562 3.45267 11.6438 3.892 11.2045L10.642 4.4545C11.0813 4.01517 11.7937 4.01517 12.233 4.4545Z" fill="#005EB8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 12C4.5 11.3787 5.00368 10.875 5.625 10.875H19.3125C19.9338 10.875 20.4375 11.3787 20.4375 12C20.4375 12.6213 19.9338 13.125 19.3125 13.125H5.625C5.00368 13.125 4.5 12.6213 4.5 12Z" fill="#005EB8" />
          </svg>
        </span>
      }
      {props.name === "more" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.2045 7.8295C11.6438 7.39017 12.3562 7.39017 12.7955 7.8295L19.5455 14.5795C19.9848 15.0188 19.9848 15.7312 19.5455 16.1705C19.1062 16.6098 18.3938 16.6098 17.9545 16.1705L12 10.216L6.0455 16.1705C5.60616 16.6098 4.89384 16.6098 4.4545 16.1705C4.01517 15.7312 4.01517 15.0188 4.4545 14.5795L11.2045 7.8295Z" fill="#005EB8" />
          </svg>
        </span>
      }



      {props.name === "arrowDown" &&
        <span className={`icon-${props.name}`}>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.21209 6.52459C4.5782 6.15847 5.1718 6.15847 5.53791 6.52459L10.5 11.4867L15.4621 6.52459C15.8282 6.15847 16.4218 6.15847 16.7879 6.52459C17.154 6.8907 17.154 7.4843 16.7879 7.85041L11.1629 13.4754C10.7968 13.8415 10.2032 13.8415 9.83709 13.4754L4.21209 7.85041C3.84597 7.4843 3.84597 6.8907 4.21209 6.52459Z" fill="#005EB8" />
          </svg>
        </span>
      }
      {props.name === "hambur" &&
        <span className={`icon-${props.name}`}>
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1.5C0 1.08579 0.335786 0.75 0.75 0.75H17.25C17.6642 0.75 18 1.08579 18 1.5C18 1.91421 17.6642 2.25 17.25 2.25H0.75C0.335786 2.25 0 1.91421 0 1.5Z" fill="#005EB8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 5.58579 0.335786 5.25 0.75 5.25H17.25C17.6642 5.25 18 5.58579 18 6C18 6.41421 17.6642 6.75 17.25 6.75H0.75C0.335786 6.75 0 6.41421 0 6Z" fill="#005EB8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 10.5C0 10.0858 0.335786 9.75 0.75 9.75H17.25C17.6642 9.75 18 10.0858 18 10.5C18 10.9142 17.6642 11.25 17.25 11.25H0.75C0.335786 11.25 0 10.9142 0 10.5Z" fill="#005EB8" />
          </svg>
        </span>
      }
      {props.name === "closeHambur" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z" fill="#005EB8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803C5.92678 17.4874 5.92678 17.0126 6.21967 16.7197L16.7197 6.21967C17.0126 5.92678 17.4874 5.92678 17.7803 6.21967Z" fill="#005EB8" />
          </svg>
        </span>
      }
      {props.name === "logOut" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 4.16667C4.07936 4.16667 3.33317 4.91286 3.33317 5.83333V14.1667C3.33317 15.0871 4.07936 15.8333 4.99984 15.8333H8.33317C9.25365 15.8333 9.99984 15.0871 9.99984 14.1667V13.3333C9.99984 12.8731 10.3729 12.5 10.8332 12.5C11.2934 12.5 11.6665 12.8731 11.6665 13.3333V14.1667C11.6665 16.0076 10.1741 17.5 8.33317 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1667V5.83333C1.6665 3.99238 3.15889 2.5 4.99984 2.5H8.33317C10.1741 2.5 11.6665 3.99238 11.6665 5.83333V6.66667C11.6665 7.1269 11.2934 7.5 10.8332 7.5C10.3729 7.5 9.99984 7.1269 9.99984 6.66667V5.83333C9.99984 4.91286 9.25365 4.16667 8.33317 4.16667H4.99984ZM13.5773 6.07741C13.9027 5.75197 14.4303 5.75197 14.7558 6.07741L18.0891 9.41074C18.4145 9.73618 18.4145 10.2638 18.0891 10.5893L14.7558 13.9226C14.4303 14.248 13.9027 14.248 13.5773 13.9226C13.2518 13.5972 13.2518 13.0695 13.5773 12.7441L15.488 10.8333L5.83317 10.8333C5.37293 10.8333 4.99984 10.4602 4.99984 10C4.99984 9.53976 5.37293 9.16667 5.83317 9.16667L15.488 9.16667L13.5773 7.25592C13.2518 6.93049 13.2518 6.40285 13.5773 6.07741Z" fill="#005EB8" />
          </svg>
        </span>
      }
      {props.name === "arrowBtn404" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.733 4.4545C13.1723 4.89384 13.1723 5.60616 12.733 6.0455L6.77849 12L12.733 17.9545C13.1723 18.3938 13.1723 19.1062 12.733 19.5455C12.2937 19.9848 11.5813 19.9848 11.142 19.5455L4.392 12.7955C3.95267 12.3562 3.95267 11.6438 4.392 11.2045L11.142 4.4545C11.5813 4.01517 12.2937 4.01517 12.733 4.4545Z" fill="#F0F7FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 12C5 11.3787 5.50368 10.875 6.125 10.875H19.8125C20.4338 10.875 20.9375 11.3787 20.9375 12C20.9375 12.6213 20.4338 13.125 19.8125 13.125H6.125C5.50368 13.125 5 12.6213 5 12Z" fill="#F0F7FF" />
          </svg>
        </span>
      }





    </div>
  )
}

export default Icon